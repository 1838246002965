export const custom = {
  /**
  * Idiomas disponibles.
  */
  availableLangs: ['es','en'],
  /**
   * Idioma por defecto
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: 'edf1845e-0760-4a5b-b403-42b54e6800fe',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'appdemoabierta.alares',

  /**
  * Nombre de la app
  */
  appName: 'APP Demo abierta',

  /**
  * Código de analytics
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-XXXXXXXXXXX',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: false,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true,

  /**
   * Configuracion del login (si no hay registro)
  */
  login: {
    loginMessageStatus: false,
    loginLink: "https://alares.es"
  },

  /**
   * Configuración del registro
   */
  registration: {
    /** Registro habilitado o deshabilitado */
    status: true,
    /** Mensaje de aviso de registro */
    registerMessageStatus: true,
    /** Muestra el ensaje de aviso de recuperación de contraseña */
    recoveryMessageStatus: true,
    /** Se permiten contraseñas no seguras */
    allowInsecurePasswords: true
  },

  /** Enlace adicional ubicado en top menu */
  externalTopLink: {
    /** Enlace habilitado o deshabilitado */
    status: false,
    /** Titulo del enlace */
    title: 'Link externo',
    /** URL del enlace */
    url: 'https://www.alares.es',
    /** Se añade la cadena `/xxxxx` a la url, donde xxxxx es el identificador del usuario en base64 */
    concatIdentifier: true
  },

  /** Se agrupan los servicios por categorías */
  groupByCategories: true,

  /**
   * Recaptcha en formularios
   * Si los formularios van protegidos con recaptcha sobreescribir esta variable en environment.custom.ts
   * dándole el valor de la clave pública
   * Valores: false|<clave_publica>
  */
  recaptcha: '6Ld60XUUAAAAAEbbjQOjWi_T7L9R_gXszlcXPyLV',

  /**
   * user y pass precargados para desarrollo
   */
  user: {
    fakeUsername: '',
    defaultPassword: ''
  }
};
